import { TripsLayer } from '@deck.gl/geo-layers';
import Blending from './blending';

const TIME0 = new Date('2023-05-18T08:00:00.000Z');
const SPEED_MULTIPLIER = 100;

function compareTrips(a, b) {
  if (a.properties.ROUTE_ID < b.properties.ROUTE_ID) {
    return -1;
  }
  else if (a.properties.ROUTE_ID > b.properties.ROUTE_ID) {
    return 1;
  }
  else {
    // Same route, we look at the sequence
    if (a.properties.SEQ < b.properties.SEQ) {
      return -1;
    }
    else {
      return 0;
    }
  }
}

function getTripsData(data) {
  let trips = [];
  let featurePath = [], featureTimestamps = [];

  // Work with relative timestamps
  let currentTime = new Date(0);

  // Order the features by route ID and seq
  data.sort(compareTrips);
  let routeID = data[0].properties.ROUTE_ID;

  for (let feature of data) {
    if (feature.properties.ROUTE_ID != routeID) {
      // Add the path and the timestamps
      trips.push({
        path: featurePath,
        timestamps: featureTimestamps
      });

      // Reinitialize variables
      featurePath = [];
      featureTimestamps = [];
      routeID = feature.properties.ROUTE_ID;
      currentTime = new Date(0); 
    }
    else {
      featurePath = featurePath.concat(feature.geometry.coordinates);
      // Simple time assignment based on total distance and number of points
      const timeIncrement = (feature.properties.DURATION / SPEED_MULTIPLIER) / feature.geometry.coordinates.length;
      feature.geometry.coordinates.forEach(point => {
        currentTime.setSeconds(currentTime.getSeconds() + timeIncrement);
        currentTime.setMilliseconds(currentTime.getMilliseconds() + 
                                    ((timeIncrement - Math.floor(timeIncrement)) * 1000));
        featureTimestamps.push(new Date(currentTime.getTime()).getTime());         
      });
    }
  }
  // Push the latest route
  trips.push({
    path: featurePath,
    timestamps: featureTimestamps
  });

  return trips;
}

export function getTripsLayer(cartoLayer) {

  const tripsData = getTripsData(cartoLayer.props.data.features);

  const tripsLayer = new TripsLayer({
    id: 'trips-layer',
    data: tripsData,
    getPath: d => d.path,
    // deduct start timestamp from each data point to avoid overflow
    getTimestamps: d => d.timestamps,
    //getColor: cartoLayer.props.getFillColor,
    getColor: [30, 200, 254],
    opacity: cartoLayer.props.opacity,
    widthMinPixels: 2 * cartoLayer.props.getPointRadius,
    capRounded: true,
    fadeTrail: true,
    trailLength: 25000,
    currentTime: 0,
    parameters: {
      depthTest: false,
      ...Blending.ADDITIVE
    }
  });

  return tripsLayer;
};
