export default [
  /* 0 */ {
    mapID: 'b1d775a0-fe07-4759-81eb-2c1c640c6e8d',
    // mapID: '23e4d84c-0b2c-423c-b163-f776ea994427',
    parameters: {depthTest: false},
  },
  /* 1 */ {
    // mapID: '48196ceb-d2e8-4275-89c4-453e37d5de9e',
    mapID: 'ca9cd5e8-480a-445a-8472-cd24b130dae7',
    parameters: {depthTest: false},
    orbit: true
  },
  /* 2 */ {
    mapID: 'b1d775a0-fe07-4759-81eb-2c1c640c6e8d',
    orbit: true
    //props: {weightsTextureSize: 256}
  }
];
