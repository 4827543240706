import React, {useCallback, useRef, useState, createContext, useContext, useEffect} from 'react';

import {LinearInterpolator, FlyToInterpolator} from '@deck.gl/core';
import {fetchMap, setDefaultCredentials} from '@deck.gl/carto';

import {getTripsLayer} from '/layers/trips';
import slides from './slides';

const initAppState = {
  currentSlide: null,
  viewState: {
    longitude: -97.72,
    latitude: 30.30,
    zoom: 11,
    bearing: 270,
    pitch: 50
  }
};

const ANIMATED_TRIPS_SLIDE = 0;

export const AppStateContext = createContext(initAppState);

const transitionInterpolator = new LinearInterpolator(['bearing', 'zoom', 'longitude', 'latitude']);

const EDGES_LABEL = 'Route Legs ';
function getEdgesLayer(layers) {
  return layers.filter(l => l.props.cartoLabel === EDGES_LABEL)[0];
}

export const AppStateStore = ({children}) => {
  const [currentSlide, setCurrentSlide] = useState(initAppState.currentSlide);
  const [layers, setLayers] = useState([]);
  const [basemap, setBasemap] = useState('https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json');
  const [viewState, setViewState] = useState(initAppState.viewState);

  const [animationFlag, setAnimationFlag] = React.useState();
  const forceUpdate = React.useCallback(() => setAnimationFlag({}), []);

  // Animation using the TripsLayer
  const maxTime = 500000;
  let currentTime = useRef(0);
  let requestRef = useRef(null);

  const orbit = useCallback(() => {
    setViewState((viewState) => ({
      ...viewState,
      bearing: viewState.bearing + 120,
      zoom: (viewState.zoom < 12) ? 12 : 11,
      transitionDuration: 20000,
      transitionInterpolator,
      onTransitionEnd: orbit
    }));
  }, []);

  const updateViewState = function (viewState, shouldOrbit) {
    setViewState({
      ...viewState,
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator(),
      onTransitionEnd: () => {
        if (shouldOrbit) {
          orbit();
        }
      }
    });
  };

  useEffect(async () => {
    setDefaultCredentials({
      accessToken:
        'eyJhbGciOiJIUzI1NiJ9.eyJhIjoiYWNfbHFlM3p3Z3UiLCJqdGkiOiI1YjI0OWE2ZCJ9.Y7zB30NJFzq5fPv8W5nkoH5lPXFWQP0uywDtqUg8y8c'
    });

    setCurrentSlide(0);
  }, [setCurrentSlide]);

  useEffect(() => {
    if (currentSlide !== null) {
      const {
        mapID: cartoMapId,
        layers,
        view,
        orbit: shouldOrbit,
        parameters,
        props,
        embed,
        image
      } = slides[currentSlide];
      if (embed === undefined && image === undefined) {
        if (cartoMapId) {
          fetchMap({cartoMapId}).then(
            ({initialViewState: mapViewState, mapStyle, layers: mapLayers}) => {

              setBasemap(`https://basemaps.cartocdn.com/gl/${mapStyle.styleType}-gl-style/style.json`);

              let _mapLayers = mapLayers.map((l) =>
                l.clone({
                  parameters: {...l.props.parameters, ...parameters},
                  ...props
                })
              );

              if (currentSlide == ANIMATED_TRIPS_SLIDE) {
                // For the animated trips slide, we need to transform the
                // Edges layer from CartoLayer to TripsLayer
                const edgesLayer = getEdgesLayer(_mapLayers);
                if (!edgesLayer) {
                  console.error(`Cannot find edges layer. Check the map has a layer with label: "${EDGES_LABEL}"`);
                }
                _mapLayers = _mapLayers.map(l => {
                  return l === edgesLayer ? getTripsLayer(l) : l
                });
              }

              setLayers(_mapLayers);

              if (currentSlide == ANIMATED_TRIPS_SLIDE) {
                mapViewState.longitude = -97.73;
                mapViewState.latitude = 30.28;
                mapViewState.zoom = 12;  
                // document.querySelector('#timer').style.display = 'block';
                // setInterval(function () {
                //   let currentDate = new Date('2023-05-18T08:00:00.000Z');
                //   currentDate.setSeconds(currentDate.getSeconds() + (currentTime.current / 1000) * 50);
                //   document.querySelector('#timer').children[0].innerText = currentDate.toLocaleString();
                // }, 200);
              }
              else {
                // document.querySelector('#timer').style.display = 'none';
              }
              updateViewState(mapViewState, shouldOrbit);

              if (currentSlide == ANIMATED_TRIPS_SLIDE) {
                // Trigger the animation manually by re-rendering the component so the animation useEffect hook is executed
                forceUpdate();
              }
            }
          );
        } else {
          setLayers(layers.map((l) => l.clone()));
          if (view && view.longitude !== undefined) {
            updateViewState(view, shouldOrbit);
          }
        }
      }
    }
  }, [currentSlide]);

  // Animation
  const animate = () => {
    currentTime.current = (currentTime.current + 10) % maxTime;
    if (layers.length > 0 && currentSlide == ANIMATED_TRIPS_SLIDE) {
      setLayers(layers.map(l => l.clone({currentTime: currentTime.current})));
      requestRef.current = requestAnimationFrame(animate);
    }
  }
  
  React.useEffect(() => {
    if (currentSlide == ANIMATED_TRIPS_SLIDE) {
      requestRef.current = requestAnimationFrame(animate);
    }
    return () => cancelAnimationFrame(requestRef.current);
  }, [currentSlide, animationFlag]); 

  return (
    <AppStateContext.Provider
      value={{
        next: () => {
          if (currentSlide < slides.length - 1) {
            setCurrentSlide(currentSlide + 1);
          }
        },
        prev: () => {
          if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
          }
        },
        reset: () => {
          setCurrentSlide(0);
        },
        currentSlide,
        layers,
        slidesNumber: slides.length,
        viewState,
        basemap
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
};

export const AppStateContextConsumer = AppStateContext.Consumer;

export function useAppState() {
  return useContext(AppStateContext);
}
